exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-params-js": () => import("./../../../src/pages/params.js" /* webpackChunkName: "component---src-pages-params-js" */),
  "component---src-templates-editorial-js": () => import("./../../../src/templates/editorial.js" /* webpackChunkName: "component---src-templates-editorial-js" */),
  "component---src-templates-generic-page-js": () => import("./../../../src/templates/genericPage.js" /* webpackChunkName: "component---src-templates-generic-page-js" */),
  "component---src-templates-pages-js": () => import("./../../../src/templates/pages.js" /* webpackChunkName: "component---src-templates-pages-js" */),
  "component---src-templates-policy-page-js": () => import("./../../../src/templates/policyPage.js" /* webpackChunkName: "component---src-templates-policy-page-js" */),
  "component---src-templates-premiere-page-js": () => import("./../../../src/templates/premierePage.js" /* webpackChunkName: "component---src-templates-premiere-page-js" */),
  "component---src-templates-tv-page-js": () => import("./../../../src/templates/tvPage.js" /* webpackChunkName: "component---src-templates-tv-page-js" */)
}

